import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import db from '../lib/firebase';
import '../styles/main.css';
import '../styles/home.css';
import Flickity from 'react-flickity-component';
import 'flickity/css/flickity.css';
import AllBoards from './allBoards'; // Import AllBoards component
import TvBoards from './tvBoards'; // Import AllBoards component
import VideogameBoards from './videogameBoards'; // Import AllBoards component




const flickityOptions = {
  initialIndex: 1,
  draggable: true,
  freeScroll: true,
  contain: true,
  wrapAround: true,
  autoPlay: 5000,
  prevNextButtons: false,
  pageDots: false,
};

const Home = () => {
  const [boards, setBoards] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBoards = async () => {
      const boardsRef = collection(db, 'bingoboards');
      const boardsSnapshot = await getDocs(boardsRef);
      const boardsData = boardsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      console.log('Fetched boards:', boardsData); // Debugging fetched data
      setBoards(boardsData);
    };

    fetchBoards();
  }, []);

  // Flickity events to track dragging
  const handleDragStart = () => setIsDragging(true);
  const handleDragEnd = () => setIsDragging(false);

    const handleStaticClick = (event, pointer, cellElement, cellIndex) => {
      console.log('staticClick event fired');
      console.log('isDragging:', isDragging);
      console.log('cellIndex:', cellIndex);
      console.log('cellElement:', cellElement);

      if (!isDragging && cellElement && boards.length > 0) {
        // Filter for featured boards
        const filteredBoards = boards.filter((board) => board.isFeatured === true);
        console.log('Filtered Boards Length:', filteredBoards.length);
        console.log('Cell Index:', cellIndex);

        if (filteredBoards[cellIndex]) {
          const selectedBoard = filteredBoards[cellIndex];
          console.log('Navigating to:', `/board/${selectedBoard.slug}-bingo`);
          navigate(`/board/${selectedBoard.slug}-bingo`);
        } else {
          console.error('Invalid click or no board found for the given index.');
        }
      }
    };
    

  return (
    <div className="homeUI">
      {boards.length > 0 && (
        <Flickity
          className={'featuredBoardsContainer'}
          style={{ height: 'auto' }}
          options={flickityOptions}
          flickityRef={(flkty) => {
            flkty.on('dragStart', handleDragStart);
            flkty.on('dragEnd', handleDragEnd);
            flkty.on('staticClick', handleStaticClick); // Only bind event when boards are available
          }}
        >
          {boards
            .filter((board) => board.isFeatured)
            .map((board, index) => (
              <div
                className="featuredBoard"
                key={board.id + index}
                style={{
                  backgroundImage: `url(${board.imageUrl})`,
                }}
              >
                <h2 className="featuredHeader alternate">{board.featuredTitle}</h2>
                <p className="featuredDesc">{board.featuredDesc}</p>
              </div>
            ))}
        </Flickity>
      )}
      <div className="sitebodyContainer">
          <div className="intro">
          <h2 className="alternate">
            a custom social party game for literally anything
          </h2>
            <p>
          share your predictions for any event. <br />
          predictions that get the most likes become tiles on the bingo board.<br />
          if it happens, tap the tile. it’s that easy.
            </ p></div>
        
          <h3 className="sectionTitle tvBoardsTitle">
          tv show boards
          </h3>
          <TvBoards
                  handleDragStart={handleDragStart}
                  handleDragEnd={handleDragEnd}
                  handleStaticClick={handleStaticClick}
                />
          
          <h3 className="sectionTitle videogameBoardsTitle">
          video game boards
          </h3>
          <VideogameBoards
                  handleDragStart={handleDragStart}
                  handleDragEnd={handleDragEnd}
                  handleStaticClick={handleStaticClick}
                />
          
          <h3 className="sectionTitle allBoardsTitle">
          all boards
          </h3>
          <AllBoards
                  handleDragStart={handleDragStart}
                  handleDragEnd={handleDragEnd}
                  handleStaticClick={handleStaticClick}
                />
          
          </div>
          
          

    </div>
  );
};

export default Home;
