import { ChakraProvider } from "@chakra-ui/react"; // Updated import
import React from "react";
import ReactDOM from "react-dom/client"; // Updated import for React 18
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root")); // Updated for React 18

root.render(
  <React.StrictMode>
            {    /*   <ChakraProvider> */}
      <App />
            { /*  </ChakraProvider> */}
  </React.StrictMode>
);
