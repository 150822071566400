// /src/components/allBoards.js
import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import Flickity from 'react-flickity-component';
import db from '../lib/firebase';
import 'flickity/css/flickity.css';

const flickityOptions = {
  initialIndex: 0,
  draggable: true,
  freeScroll: true,
  contain: true,
  wrapAround: false,
  prevNextButtons: false,
  pageDots: false,
    cellAlign: 'left',

};

const AllBoards = ({ handleDragStart, handleDragEnd, handleStaticClick }) => {
  const [boards, setBoards] = useState([]);

  useEffect(() => {
    const fetchBoards = async () => {
      const boardsRef = collection(db, 'bingoboards');
      const boardsSnapshot = await getDocs(boardsRef);
      const boardsData = boardsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBoards(boardsData);
    };

    fetchBoards();
  }, []);

  return (
          
    boards.length > 0 && (
      <Flickity
        className={'boardSectionContainer allBoardsContainer'}
        style={{ height: 'auto' }}
        options={flickityOptions}
        flickityRef={(flkty) => {
          flkty.on('dragStart', handleDragStart);
          flkty.on('dragEnd', handleDragEnd);
          flkty.on('staticClick', handleStaticClick);
        }}
      >
                          
                          {/*
                            <div className="boardSection allBoards placeholder" style={{ backgroundColor: 'transparent' }}>
                            </div>
                            */}

                          
        {boards.map((board, index) => (
          <div
            className="boardSection allBoards"
            key={board.id + index}
            style={{ backgroundImage: `url(${board.imageUrl})` }}
          >
            <h2 className="boardTitle">{board.title}</h2>
          </div>
        ))}
                          
                          {/*
                            <div className="boardSection allBoards placeholder" style={{ backgroundColor: 'transparent' }}>
                            </div>
                            */}
      </Flickity>
    )
  );
};

export default AllBoards;
