import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore'; // Import necessary functions
import Flickity from 'react-flickity-component';
import db from '../lib/firebase';
import 'flickity/css/flickity.css';

const flickityOptions = {
  initialIndex: 0,
  draggable: true,
  freeScroll: true,
  contain: true,
  wrapAround: false,
  prevNextButtons: false,
  pageDots: false,
  cellAlign: 'left',
};

const VideogameBoards = ({ handleDragStart, handleDragEnd, handleStaticClick }) => {
  const [boards, setBoards] = useState([]);

  useEffect(() => {
    const fetchBoards = async () => {
      // Create a reference to the 'bingoboards' collection and apply a query
      const boardsRef = collection(db, 'bingoboards');
      const q = query(boardsRef, where('category', '==', 'videogame')); // Query where category is "tv"
      
      const boardsSnapshot = await getDocs(q); // Fetch the filtered documents
      const boardsData = boardsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBoards(boardsData); // Set the filtered boards
    };

    fetchBoards();
  }, []);

  return (
    boards.length > 0 && (
      <Flickity
        className={'boardSectionContainer allBoardsContainer'}
        style={{ height: 'auto' }}
        options={flickityOptions}
        flickityRef={(flkty) => {
          flkty.on('dragStart', handleDragStart);
          flkty.on('dragEnd', handleDragEnd);
          flkty.on('staticClick', handleStaticClick);
        }}
      >
        {boards.map((board, index) => (
          <div
            className="boardSection allBoards"
            key={board.id + index}
            style={{ backgroundImage: `url(${board.imageUrl})` }}
          >
            <h2 className="boardTitle">{board.title}</h2>
          </div>
        ))}
      </Flickity>
    )
  );
};

export default VideogameBoards;
