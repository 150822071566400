import React from 'react';

const suggestionSidecar = () => {
  return (
    <div className="column column-2">
      <div className="row row-1">
        <h2>Have a prediction?<br />Leave a comment.</h2>
      </div>
      <div className="row row-2 bg-theming">
        <div className="foreground-content">
          <h2 className="bingoHeader">X days X hours X minutes<br />until it's time to play!</h2>
        </div>
      </div>
    </div>
  );
};

export default suggestionSidecar;
