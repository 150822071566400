import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/header';
import Home from './components/home';
import BingoBoard from './components/bingoBoard';
import Login from './components/login';
import Me from './components/me';
import Search from './components/search';
import CreateBoard from './components/createBoard';

import ProtectedRoute from './components/protectedRoute';
import { AuthProvider, useAuth } from './components/authContext'; // Import useAuth

import './styles/main.css';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/board/:boardTitle" element={<BingoBoard />} />
          {/* Redirect to /me if user is logged in, else show Login */}
          <Route
            path="/login"
            element={<RedirectLogin />}
          />
              <Route
                path="/search"
                element={<Search />}
              />
              <Route
                path="/create"
                element={<CreateBoard />}
              />
          <Route
            path="/me"
            element={
              <ProtectedRoute>
                <Me />
              </ProtectedRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

// Helper component to handle redirection based on authentication
const RedirectLogin = () => {
  const { user } = useAuth(); // Get user status from context
  return user ? <Navigate to="/me" /> : <Login />;
};

export default App;
