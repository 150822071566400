import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import db from '../lib/firebase';
import '../styles/search.css';

const Search = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [boards, setBoards] = useState([]);
  const [filteredBoards, setFilteredBoards] = useState([]);

  // Fetch all boards from Firestore on component mount
  useEffect(() => {
    const fetchBoards = async () => {
      const boardsRef = collection(db, 'bingoboards');
      const boardsSnapshot = await getDocs(boardsRef);
      const boardsData = boardsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBoards(boardsData);
    };

    fetchBoards();
  }, []);

  // Filter boards as the user types
  useEffect(() => {
    if (searchTerm.trim() === '') {
      setFilteredBoards([]);
    } else {
      const filtered = boards.filter((board) =>
        board.title.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredBoards(filtered);
    }
  }, [searchTerm, boards]);

  return (
    <div className="searchContainer">
          <div className="searchTitle"><h2>find a bingo game, or make your own</h2></div>
      <input
        type="text"
        className="searchInput"
        placeholder="start typing..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Only display the results if the searchTerm is not empty */}
      {searchTerm.trim() !== '' && (
        <div className="resultsGrid">
          {filteredBoards.length === 0 ? (
            // If no results, show only the "create new board" tile
            <div className="boardSection createBoardTile">
                                          <Link to={`/create`}>
                <h2 className="boardTitle">
                  Make a new board called "{searchTerm}"
                </h2>
              </Link>
            </div>
          ) : (
            <>
              {filteredBoards.map((board, index) => (
                <div
                  className="boardSection"
                  key={board.id}
                  style={{ backgroundImage: `url(${board.imageUrl})` }}
                >
                  <Link to={`/board/${board.slug}`}>
                    <h2 className="boardTitle">{board.title}</h2>
                  </Link>
                </div>
              ))}
              {/* Display "create new board" tile based on results count */}
              {(filteredBoards.length <= 2 || filteredBoards.length > 2) && (
                <div className="boardSection createBoardTile">
                                                                             <Link to={`/create`}>
                    <h2 className="boardTitle">
                      Make a new board called "{searchTerm}"
                    </h2>
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Search;
