import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import db, { auth } from '../lib/firebase';
import { useNavigate } from 'react-router-dom';
import '../styles/me.css';

const Me = () => {
  const [user, setUser] = useState(null);
  const [savedColor, setSavedColor] = useState(null);
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [isEditingDisplayName, setIsEditingDisplayName] = useState(false);
  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isColorPickerVisible, setIsColorPickerVisible] = useState(false);
  const navigate = useNavigate();

  // Listen for authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setEmail(currentUser.email);
        loadUserData(currentUser.uid);
      } else {
        setUser(null);
      }
    });
    return () => unsubscribe();
  }, []);

  // Load user's saved color and display name from Firestore
  const loadUserData = async (uid) => {
    const userDocRef = doc(db, 'users', uid);
    const docSnap = await getDoc(userDocRef);
    if (docSnap.exists()) {
      const userData = docSnap.data();
      setSavedColor(userData.favoriteColor || '');
      setDisplayName(userData.displayName || '');
    }
  };

  // Save user's favorite color and other data to Firestore
  const saveUserData = async () => {
    if (user) {
      const userDocRef = doc(db, 'users', user.uid);
      await setDoc(userDocRef, { displayName, email, favoriteColor: savedColor }, { merge: true });
    }
  };

  // Handle display name editing
  const handleDisplayNameClick = () => {
    setIsEditingDisplayName(true);
  };

  // Handle email editing
  const handleEmailClick = () => {
    setIsEditingEmail(true);
  };

  // Handle enter key to save display name and email
  const handleKeyDownDisplayName = (e) => {
    if (e.key === 'Enter') {
      setIsEditingDisplayName(false);
      saveUserData();
    }
  };

  const handleKeyDownEmail = (e) => {
    if (e.key === 'Enter') {
      setIsEditingEmail(false);
      saveUserData();
    }
  };

  // Logout handler
  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login'); // Redirect to login page
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  // Open the color picker
  const handleColorClick = () => {
    setIsColorPickerVisible(true);
  };

  // Handle color change
  const handleColorChange = (e) => {
    setSavedColor(e.target.value);
  };

  // Handle closing of color picker
  const handleColorPickerClose = () => {
    setIsColorPickerVisible(false);
    saveUserData(); // Save the color to Firestore when closing
  };

  if (!user) {
    return <div>Please log in to see your profile information.</div>;
  }

  return (
    <div className="meContainer">
          <h2
          style={{ marginTop: '2rem', marginBottom: '0rem' }}
          >
          your everybingo profile
          </h2>
          <p
          style={{ marginTop: '0rem', paddingTop: '0' }}
          >tap an item to change it</p>
      <div className="nametag uiSection">
        <div
          className="colorCircle"
          style={{ backgroundColor: savedColor || 'gray' }}
          onClick={handleColorClick} // Open color picker on click
        ></div>
        {isEditingDisplayName ? (
          <input
            type="text"
            value={displayName}
            onChange={(e) => setDisplayName(e.target.value)}
            onKeyDown={handleKeyDownDisplayName}
            className="displayNameInput"
            autoFocus
          />
        ) : (
          <h2 onClick={handleDisplayNameClick}>
            {displayName || 'enter your name'}
          </h2>
        )}
      </div>

      <div className="secretInfo uiSection">
        <h3>secret personal info</h3>

        <div className="contentSection">
          <p>email:</p>
          {isEditingEmail ? (
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={handleKeyDownEmail}
              className="emailInput"
              autoFocus
            />
          ) : (
            <p onClick={handleEmailClick}>
              {email}
            </p>
          )}
        </div>

        <div className="contentSection">
          <p>password:</p>
          <p>Click to change password</p>
        </div>

        <div className="contentSection">
          <button onClick={handleLogout} style={{ marginTop: '20px' }}>
            Logout
          </button>
        </div>
      </div>

      {isColorPickerVisible && (
        <input
          type="color"
          value={savedColor || '#000000'} // Default color if none saved
          onChange={handleColorChange} // Update color
          onBlur={handleColorPickerClose} // Close color picker when focus is lost
          style={{ position: 'absolute', top: '20%', left: '50%', transform: 'translate(-50%, -50%)' }} // Position the color picker
        />
      )}
    </div>
  );
};

export default Me;
