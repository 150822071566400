import { initializeApp } from "firebase/app";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth, connectAuthEmulator } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAqBsqtn6OZLRpn0ZnZpkfoYUdrGyj0SgU",
  authDomain: "bingo-app-c27b4.firebaseapp.com",
  projectId: "bingo-app-c27b4",
  storageBucket: "bingo-app-c27b4.appspot.com",
  messagingSenderId: "1088195557792",
  appId: "1:1088195557792:web:4d1b0484ee675f38bd8cc5",
  measurementId: "G-LQ72XN7KR8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

// Use emulators in development
if (window.location.hostname === "localhost") {
  connectAuthEmulator(auth, "http://localhost:9099");
    connectFirestoreEmulator(db, "localhost", 8080); // Adjust port if necessary

}

export { auth, analytics };
export default db;
