import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../components/authContext';

const ProtectedRoute = ({ children }) => {
  const { user } = useAuth();
  
  // If the user is not authenticated and trying to access /me, redirect to /login
  if (!user) {
    return <Navigate to="/login" />;
  }

  // If the user is authenticated, render the children
  return children;
};

export default ProtectedRoute;
