import React, { useEffect, useState } from 'react';
import { collection, query, orderBy, getDocs, getDoc, updateDoc, doc, addDoc, serverTimestamp } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import db, { auth } from '../lib/firebase';
import '../styles/bingoSidecar.css';
import heartIconOutline from '../assets/heart.svg';
import heartIconFill from '../assets/heart-fill.svg';
import sidecarIcon from '../assets/bar.svg';
import circleIcon from '../assets/circle.svg';


const BingoSidecar = ({ boardId, isSidecarOpen, toggleSidecar }) => {
  const [suggestions, setSuggestions] = useState([]);
  const [newSuggestion, setNewSuggestion] = useState(''); // Initialize newSuggestion state
  const [user, setUser] = useState(null);

  // Define the fetchSuggestions function
  const fetchSuggestions = async () => {
    try {
      if (!boardId) return;

      const suggestionsRef = collection(db, "bingoboards", boardId, "suggestions");
      const suggestionsQuery = query(suggestionsRef, orderBy("likes", "desc"));
      const suggestionsSnapshot = await getDocs(suggestionsQuery);

      const suggestionsData = suggestionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const storedLikes = suggestionsData.reduce((acc, suggestion) => {
        acc[suggestion.id] = localStorage.getItem(`liked_${suggestion.id}`) === 'true';
        return acc;
      }, {});

      setSuggestions(suggestionsData.map(suggestion => ({
        ...suggestion,
        liked: storedLikes[suggestion.id] || false,
      })));
    } catch (error) {
      console.error("Error fetching suggestions: ", error);
    }
  };

  useEffect(() => {
    // Fetch suggestions when the component mounts or when boardId changes
    fetchSuggestions();
  }, [boardId]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, []);

  const handleLikeToggle = async (suggestion) => {
    if (!user) return; // Do nothing if user is not logged in

    const updatedLikes = suggestion.liked ? suggestion.likes - 1 : suggestion.likes + 1;

    const suggestionRef = doc(db, "bingoboards", boardId, "suggestions", suggestion.id);
    await updateDoc(suggestionRef, { likes: updatedLikes });

    setSuggestions((prevSuggestions) =>
      prevSuggestions.map((s) =>
        s.id === suggestion.id ? { ...s, liked: !s.liked, likes: updatedLikes } : s
      )
    );

    localStorage.setItem(`liked_${suggestion.id}`, !suggestion.liked);
  };

    const handleNewSuggestionSubmit = async () => {
      if (!newSuggestion.trim()) return;

      try {
        // Fetch the user's display name from the `users` collection
        const userRef = doc(db, "users", user.uid);
        const userSnap = await getDoc(userRef);

        if (!userSnap.exists()) {
          console.error("User document not found!");
          return;
        }

        const userData = userSnap.data();
        const displayName = userData.displayName || 'an everybingo user';

        const suggestionsRef = collection(db, "bingoboards", boardId, "suggestions");
        const newSuggestionDoc = await addDoc(suggestionsRef, {
          content: newSuggestion,
          likes: 1,
          username: displayName,
          timestamp: serverTimestamp(),
        });

        // Create a temporary suggestion object to add to the list
        const tempSuggestion = {
          id: newSuggestionDoc.id,
          content: newSuggestion,
          likes: 1,
          username: displayName,
          liked: true, // Assume the user likes their own suggestion
        };

        // Update local storage to reflect the liked status
        localStorage.setItem(`liked_${newSuggestionDoc.id}`, 'true');

        // Temporarily add the new suggestion to the top of the list
        setSuggestions((prevSuggestions) => [tempSuggestion, ...prevSuggestions]);

        setNewSuggestion('');

        // Re-fetch suggestions to update the list with the correct order
        fetchSuggestions();
      } catch (error) {
        console.error("Error adding suggestion: ", error);
      }
    };
  return (
    <div className={`sidecar-container ${isSidecarOpen ? "open" : "closed"}`}>
      <div className="sidecar-left-column">
        <img
          src={sidecarIcon}
          alt="Toggle sidecar"
          className="toggle-icon"
          onClick={toggleSidecar}
        />
      </div>

      <div className="sidecar-content">
        <div className="row row-1">
          <h2>here’s the rules</h2>
          <p className="sidecar-description">if a prediction on your bingo board happens, tap it to mark it off. whoever marks off the most tiles wins.</p>
        </div>
        <div className="row row-2">
          <h2>view everyone's predictions</h2>
          <p className="sidecar-description">visit a bingo board early to leave predictions. the predictions with the most likes make it on the bingo board!</p>
          <div className="scrollable-suggestions">
            {suggestions.length > 0 ? (
              suggestions.map((suggestion) => (
                <div key={suggestion.id} className="suggestion-item">
                  <div className="suggestion-content">
                    <span className="suggestion-text">{suggestion.content}</span>
                    <p className="suggestion-author">
                      {suggestion.username ? `by ${suggestion.username}` : 'by an everybingo user'}
                    </p>
                  </div>
                  <div className="suggestion-details">
                    <div className="like-count" onClick={() => user && handleLikeToggle(suggestion)}>
                      <span>{suggestion.likes}</span>
                      <img
                        src={suggestion.liked ? heartIconFill : heartIconOutline}
                        width="20px"
                        alt="Likes"
                      />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No suggestions available</p>
            )}
          </div>
          {user && (
            <div className="new-suggestion-input">
              <input
                type="text"
                placeholder="Leave a suggestion..."
                value={newSuggestion}
                onChange={(e) => setNewSuggestion(e.target.value)}
              />
              <button onClick={handleNewSuggestionSubmit}>send</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BingoSidecar;
