import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import menuIcon from '../assets/menu.svg';
import headerLogo from '../assets/everybingo-logo.png';
import headerLogoDark from '../assets/everybingo-logo-dark.png';
import circleIcon from '../assets/circle.svg';

const Header = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    setIsDarkMode(mediaQuery.matches);

    const handleChange = (e) => setIsDarkMode(e.matches);
    mediaQuery.addEventListener('change', handleChange);

    return () => {
      mediaQuery.removeEventListener('change', handleChange);
    };
  }, []);

  return (
    <div className="header">
      <div className="headerLeft">
        <img src={menuIcon} alt="Menu" width="15px" />
      </div>
      <div className="headerMiddle">
        <Link to="/">
          <img
            className="headerLogo"
            src={isDarkMode ? headerLogoDark : headerLogo}
            alt="Logo"
          />
        </Link>
      </div>
      <div className="headerRight">
        <Link to="/login">
          <img src={circleIcon} alt="Circle" width="15px" />
        </Link>
        {/* Add the "Play" button */}
        <Link to="/search" className="playButton">
          <div className="playButtonContainer"><h3>play</h3></div>
        </Link>
      </div>
    </div>
  );
};

export default Header;
