

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { collection, query, where, getDocs } from 'firebase/firestore';
import db from '../lib/firebase';
import '../styles/main.css';
import '../styles/bingoBoard.css';
import SuggestionSidecar from './suggestionSidecar';
import BingoSidecar from './bingoSidecar';
import circleIcon from '../assets/circle.svg';
import refreshIcon from '../assets/refresh.svg';


// Import your PNG assets
import redStamp from '../assets/stamps/red.png';
import pinkStamp from '../assets/stamps/pink.png';
import blueStamp from '../assets/stamps/blue.png';
import greenStamp from '../assets/stamps/green.png';

const BingoBoard = () => {
  const { boardTitle } = useParams(); // Get the board title from URL params
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTiles, setSelectedTiles] = useState(new Array(25).fill(false));
  const [boardStatus, setBoardStatus] = useState("");
  const [boardId, setBoardId] = useState(null);
    const [boardTitleFromDb, setBoardTitleFromDb] = useState('');
  const [isSidecarOpen, setIsSidecarOpen] = useState(true);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState('');
    
    const stampImages = [redStamp, pinkStamp, blueStamp, greenStamp];

    
    
    
  const cleanBoardTitle = boardTitle.endsWith('-bingo')
    ? boardTitle.slice(0, -6) // Remove the last 6 characters ('-bingo')
    : boardTitle;
    
  console.log("Clean Board Title:", cleanBoardTitle); // Log the cleaned title


  // Weighted random selection algorithm
    const weightedRandomSelection = (suggestionsData) => {
      const totalLikes = suggestionsData.reduce((acc, suggestion) => acc + suggestion.likes, 0);
      const alpha = totalLikes < 100 ? 0.5 : 2;

      const weights = suggestionsData.map((suggestion) => ({
        ...suggestion,
        weight: Math.pow(suggestion.likes + 1, alpha)
      }));

      const totalWeight = weights.reduce((acc, suggestion) => acc + suggestion.weight, 0);
      weights.forEach(suggestion => {
        suggestion.normalizedWeight = suggestion.weight / totalWeight;
      });

      const selectedSuggestions = [];
      const uniqueSuggestions = new Set(); // To keep track of unique selections

      while (selectedSuggestions.length < 25 && weights.length > 0) {
        const random = Math.random();
        let cumulativeWeight = 0;

        for (let i = 0; i < weights.length; i++) {
          cumulativeWeight += weights[i].normalizedWeight;
          if (random <= cumulativeWeight) {
            if (!uniqueSuggestions.has(weights[i].id)) { // Ensure uniqueness
              selectedSuggestions.push(weights[i]);
              uniqueSuggestions.add(weights[i].id); // Add to the set of selected IDs
            }
            weights.splice(i, 1);
            break;
          }
        }
      }

      return selectedSuggestions;
    };

 
    const fetchBoardData = async () => {
        try {
          const boardsQuery = query(
            collection(db, "bingoboards"),
            where("slug", "==", cleanBoardTitle)
          );

          const boardSnapshot = await getDocs(boardsQuery);

          if (boardSnapshot.empty) {
            console.log("No matching bingo board found.");
            setLoading(false);
            return;
          }

          const boardDoc = boardSnapshot.docs[0];
          const boardData = boardDoc.data();
          const boardId = boardDoc.id;

          console.log({ boardId });

          setBoardId(boardId);
          setBoardTitleFromDb(boardData.title);
          setBackgroundImageUrl(boardData.imageUrl || '');
          setBoardStatus(boardDoc.data().status || "");

          const savedData = JSON.parse(localStorage.getItem(`bingoBoardData_${boardId}`));
          let selectedSuggestions = [];

          if (savedData && savedData.suggestions) {
            // If saved suggestions exist, use them
            selectedSuggestions = savedData.suggestions;
            setSuggestions(selectedSuggestions);
          } else {
            // Fetch suggestions from the database if no saved suggestions
            const suggestionsRef = collection(db, 'bingoboards', boardId, 'suggestions');
            const suggestionsSnapshot = await getDocs(suggestionsRef);

            const suggestionsData = suggestionsSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            // Select 25 tiles using weighted random selection
            selectedSuggestions = weightedRandomSelection(suggestionsData);
            setSuggestions(selectedSuggestions); // Set the selected suggestions for the board

            // Save selected suggestions to local storage
              localStorage.setItem(`bingoBoardData_${boardId}`, JSON.stringify({
                suggestions: selectedSuggestions,
              }));
          }

        } catch (error) {
          console.error('Error fetching board data: ', error);
        } finally {
          setLoading(false);
        }
      };

      useEffect(() => {
        fetchBoardData(); // Fetch board data when the component mounts or cleanBoardTitle changes
      }, [cleanBoardTitle]);



    const handleTileClick = (index) => {
      const updatedSelectedTiles = [...selectedTiles];
      const isMobile = window.innerWidth <= 768; // Define a breakpoint for mobile (768px width)

      if (updatedSelectedTiles[index]) {
        // If the tile is already selected, deselect it
        updatedSelectedTiles[index] = null;
      } else {
        // Randomly select a stamp and apply random rotation and offset
        const randomStamp = stampImages[Math.floor(Math.random() * stampImages.length)];
        const randomRotation = Math.floor(Math.random() * 360);
        
        // Adjust random offset based on screen size (less variability on mobile)
        const randomOffsetX = isMobile
          ? Math.floor(Math.random() * 40) - 10 // Mobile: offset between -20px and 20px
          : Math.floor(Math.random() * 100) - 50; // Desktop: offset between -50px and 50px
        
        const randomOffsetY = isMobile
          ? Math.floor(Math.random() * 40) - 30 // Mobile: offset between -20px and 20px
          : Math.floor(Math.random() * 100) - 50; // Desktop: offset between -50px and 50px

        updatedSelectedTiles[index] = {
          image: randomStamp,
          rotation: randomRotation,
          offsetX: randomOffsetX,
          offsetY: randomOffsetY
        };
      }

      // Update state and save to local storage
      setSelectedTiles(updatedSelectedTiles);
        localStorage.setItem(`bingoSelectedTiles_${boardId}`, JSON.stringify(updatedSelectedTiles)); // Save selected tiles to local storage
    };
    
    const clearSelectionsAndFetchNewSuggestions = () => {
      // Clear local storage
        localStorage.removeItem(`bingoBoardData_${boardId}`); // Clear saved suggestions
        localStorage.removeItem(`bingoSelectedTiles_${boardId}`); // Clear saved tile selections

      // Reset state for suggestions and selected tiles
      setSuggestions([]); // Clear current suggestions
      setSelectedTiles(Array(25).fill(null)); // Assuming a standard bingo board of 25 tiles

      // Re-fetch board data to get new suggestions
      fetchBoardData(); // Call your existing fetchBoardData function
    };

    useEffect(() => {
      if (boardId) {
        const savedTiles = JSON.parse(localStorage.getItem(`bingoSelectedTiles_${boardId}`));
        if (savedTiles) {
          setSelectedTiles(savedTiles); // Set the saved tiles to the state
        }
      }
    }, [boardId]); // Run when boardId changes

    const toggleSidecar = () => {
      setIsSidecarOpen(!isSidecarOpen);
    };

    // Add this function to fetch a new suggestion for a specific tile
    const fetchNewSuggestionForTile = async (currentSuggestions) => {
      try {
        const suggestionsRef = collection(db, 'bingoboards', boardId, 'suggestions');
        const suggestionsSnapshot = await getDocs(suggestionsRef);

        const suggestionsData = suggestionsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filter out suggestions that are currently on the board
        const availableSuggestions = suggestionsData.filter(
          suggestion => !currentSuggestions.some(cs => cs.id === suggestion.id)
        );

        if (availableSuggestions.length === 0) return null;

        // Select a random suggestion from the available ones
        return availableSuggestions[Math.floor(Math.random() * availableSuggestions.length)];
      } catch (error) {
        console.error('Error fetching new suggestion: ', error);
        return null;
      }
    };

    // Update the handleRefresh function to refresh a specific tile
    const handleRefreshTile = async (index) => {
      const newSuggestion = await fetchNewSuggestionForTile(suggestions);
      if (newSuggestion) {
        const updatedSuggestions = [...suggestions];
        updatedSuggestions[index] = newSuggestion;
        setSuggestions(updatedSuggestions);

        // Update local storage with the new suggestions
        localStorage.setItem(`bingoBoardData_${boardId}`, JSON.stringify({
          suggestions: updatedSuggestions,
        }));
      }
    };

    // Modify this part to include the refresh icon inside each tile
    const bingoSuggestions = suggestions.map((suggestion, i) => (
      <div
        key={i}
        className="bingo-square"
        onClick={() => handleTileClick(i)}
        style={{ position: 'relative' }}
      >
        {i === 12 ? "" : (
          <>
            <div className="suggestion-content">
              <p> {suggestion.content} </p>
            </div>
            <p className="suggestion-author">
              {suggestion.username ? `by ${suggestion.username}` : 'by an everybingo user'}
            </p>
                                       <img
                                         src={refreshIcon}
                                         alt="Refresh"
                                         className="refresh-icon-image"
                                         onClick={(e) => {
                                           e.stopPropagation(); // Prevent triggering the tile click
                                           handleRefreshTile(i);
                                         }}
                                         style={{ position: 'absolute', cursor: 'pointer' }}
                                       />
          </>
        )}
        {selectedTiles[i] && (
          <img
            src={selectedTiles[i].image}
            alt="Selected"
            style={{
              position: 'absolute',
              top: `calc(50% + ${selectedTiles[i].offsetY}px)`,
              left: `calc(50% + ${selectedTiles[i].offsetX}px)`,
              transform: `translate(-50%, -50%) rotate(${selectedTiles[i].rotation}deg)`,
              width: window.innerWidth <= 768 ? '50px' : '100px',
              height: window.innerWidth <= 768 ? '50px' : '100px',
              opacity: 0.87,
              zIndex: 1
            }}
          />
        )}
      </div>
    ));
    

  if (loading) {
    return <div>Loading...</div>;
  }

    return (
      <div className={`scrollfixer ${isSidecarOpen ? '' : 'enabled'}`}>
        <div className={`mainUI ${isSidecarOpen ? '' : 'shift-right'}`}>
          {/* Column-1 with background and content */}
          <div className="column column-1">
            {/* Blurred background fills the entire column */}
            <div className="blurred-bg" style={{ backgroundImage: `url(${backgroundImageUrl})` }}></div>
            
            {/* Title Section */}
            <div className="title-section">
              <h2 className="bingoHeader alternate">{boardTitleFromDb}</h2>
              
              {/* Inline Refresh Icon positioned in the top-right corner of the title section */}
              <div className="refresh-container">
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 110.4 151.221"
                  className="refresh-icon"
                  onClick={clearSelectionsAndFetchNewSuggestions} // Trigger the refresh logic
                  style={{ cursor: 'pointer' }} // Optional: Add styling like cursor on hover
                >
                  <g>
                    <rect height="151.221" opacity="0" width="110.4" x="0" y="0" />
                    <path
                      d="M0 79.9805C0 110.742 24.5117 135.303 55.2246 135.303C85.8887 135.303 110.4 110.742 110.4 79.9805C110.4 77.3926 108.545 75.5371 105.908 75.5371C103.369 75.5371 101.758 77.3926 101.758 79.9805C101.758 105.957 81.1035 126.66 55.2246 126.66C29.2969 126.66 8.64258 105.957 8.64258 79.9805C8.64258 54.1504 29.2969 33.5449 55.2246 33.5449C60.6445 33.5449 65.8203 34.082 69.873 35.1074L49.2188 55.7617C48.3398 56.6406 47.9492 57.7148 47.9492 58.8379C47.9492 61.2793 49.8047 63.1348 52.1973 63.1348C53.5156 63.1348 54.541 62.7441 55.3223 61.9141L81.8359 35.1562C82.9102 34.0332 83.3496 32.959 83.3496 31.7383C83.3496 30.5176 82.8613 29.2969 81.8359 28.3203L55.3223 1.31836C54.5898 0.439453 53.5645 0 52.1973 0C49.7559 0 47.9492 1.95312 47.9492 4.39453C47.9492 5.51758 48.3398 6.64062 49.1699 7.51953L67.9199 26.2695C64.3555 25.4883 59.8633 24.9023 55.2246 24.9023C24.5117 24.9023 0 49.3652 0 79.9805Z"
                    />
                  </g>
                </svg>
              </div>
            </div>

            {/* Bingo Board Section */}
            <div className="bingo-board-wrapper">
              <div className="bingo-board-grid">
                {bingoSuggestions}
              </div>
              {/* Free Space Overlay */}
            <div className="free-space-overlay-grid">
              {Array.from({ length: 25 }).map((_, index) => (
                <div
                  key={index}
                  className={`free-space-tile ${index === 12 ? 'free-space-center' : ''}`}
                >
                  {index === 12 && <h3 className="alternate">Free Space</h3>}
                </div>
              ))}
            </div>
            </div>
          </div>

          {/* Sidecar Component */}
          {boardStatus === "final" ? (
            <BingoSidecar
              boardId={boardId}
              isSidecarOpen={isSidecarOpen}
              toggleSidecar={toggleSidecar}
            />
          ) : (
            <SuggestionSidecar
              boardId={boardId}
              isSidecarOpen={isSidecarOpen}
              toggleSidecar={toggleSidecar}
            />
          )}
        </div>
      </div>
    );
};

export default BingoBoard;
