import React, { useState } from 'react';
import { collection, addDoc, Timestamp } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import db from '../lib/firebase';
import '../styles/createBoard.css';

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
const SEARCH_ENGINE_ID = process.env.REACT_APP_SEARCH_ENGINE_ID;

async function fetchPromoImage(boardTitle) {
  const query = `${boardTitle} imagesize:1280x720`;
  const url = `https://www.googleapis.com/customsearch/v1?key=${API_KEY}&cx=${SEARCH_ENGINE_ID}&q=${encodeURIComponent(query)}&searchType=image&num=1`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    return data.items?.[0]?.link || null;
  } catch (error) {
    console.error('Error fetching image:', error);
    return null;
  }
}

const CreateBoard = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract search term from the previous page (search query)
  const query = new URLSearchParams(location.search).get('q') || '';

  // State to store form data
  const [formData, setFormData] = useState({
    title: query,
    isPublic: null,
    category: '',
    description: '',
  });

  const [step, setStep] = useState(1);

  // Handle input change for each field
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle selection for isPublic
  const handlePublicChoice = (isPublic) => {
    setFormData({ ...formData, isPublic });
    setStep(step + 1);
  };

  // Handle navigation between steps
  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  // Submit form data to Firestore
  const handleSubmit = async () => {
    const { title, isPublic, category, description } = formData;

    // Generate a slug and fetch a promotional image
    const slug = createSlug(title);
    const imageUrl = await fetchPromoImage(title);

    try {
      // Create a new board document
      const boardRef = await addDoc(collection(db, 'bingoboards'), {
        title,
        slug,
        isPublic,
        category,
        description,
        imageUrl: imageUrl || 'No image available',
        eventDate: Timestamp.fromDate(new Date()),
        status: 'final',
        suggestionCutoff: Timestamp.fromDate(new Date()),
        votingCutoff: Timestamp.fromDate(new Date()),
        finalizedTiles: [],
      });

      console.log(`Board created with ID: ${boardRef.id}`);
      navigate(`/board/${slug}`); // Redirect to the newly created board
    } catch (error) {
      console.error('Error creating board:', error);
    }
  };

  // Helper function to generate a slug
  const createSlug = (title) => {
    return title
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^a-z0-9-]+/g, '')
      .replace(/--+/g, '-')
      .replace(/^-|-$/g, '')
      .trim();
  };

  // Page Components
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <>
            <h2>Enter a Name for Your Board</h2>
            <section className="inputSection">
              <input
                type="text"
                name="title"
                value={formData.title}
                onChange={handleChange}
                placeholder="Enter board title"
              />
              <button onClick={nextStep}>Next</button>
            </section>
          </>
        );
      case 2:
        return (
          <>
            <h2>Is this board public?</h2>
            <section className="choiceSection">
              <div onClick={() => handlePublicChoice(true)}>Public</div>
              <div onClick={() => handlePublicChoice(false)}>Private</div>
            </section>
          </>
        );
      case 3:
        return (
          <>
            <h2>Select a Category</h2>
            <section className="inputSection">
              <select name="category" value={formData.category} onChange={handleChange}>
                <option value="">Select Category</option>
                <option value="tv show">TV Show</option>
                <option value="movie">Movie</option>
                <option value="music">Music</option>
                <option value="other event">Other Event</option>
              </select>
              <button onClick={nextStep}>Next</button>
            </section>
          </>
        );
      case 4:
        return (
          <>
            <h2>Describe Your Board</h2>
            <section className="inputSection">
              <textarea
                name="description"
                value={formData.description}
                onChange={handleChange}
                placeholder="Enter a description"
              ></textarea>
              <button onClick={nextStep}>Next</button>
            </section>
          </>
        );
      case 5:
        return (
          <>
            <h2>Review Your Inputs</h2>
            <section className="reviewSection">
              <p><b>Title:</b> {formData.title}</p>
              <p><b>Public:</b> {formData.isPublic ? 'Yes' : 'No'}</p>
              <p><b>Category:</b> {formData.category}</p>
              <p><b>Description:</b> {formData.description}</p>
              <button onClick={handleSubmit}>Confirm and Create Board</button>
              <button onClick={prevStep}>Back</button>
            </section>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="createBoardContainer">
      {renderStep()}
    </div>
  );
};

export default CreateBoard;
